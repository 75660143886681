import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { MDBContainer } from "mdbreact";
import Home from "./pages/homePage.component";
import Loading from "./pages/loading.component";
import Profile from "./pages/profile.component";
import Dashboard from "./pages/dashboard.component";
import PrivateRoute from "./routing/PrivateRoute";
// import Scrollbar from "./scrollbar/scrollbar.component";
// import { prettyTime } from "./global.js";
import "./global.styles.css";

const Main = () => {
    // const SidebarContext = useContext(sidebarContext);
    // const { present, show, windowWidth, breakWidth } = SidebarContext;

    // const integrity = windowWidth > breakWidth;
    // const containerProps = present ? {
    //     fluid: true,
    //     className: "my-3"
    // } : {
    //     className: "fluid my-3"
    // };

    return (
        <main>
            <MDBContainer className="fluid"
            // {...containerProps}
            >
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/login" component={Loading} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <Route exact path="/profile/:user" component={Profile} />
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                </Switch>
            </MDBContainer>
        </main>
    );
};

export default withRouter(Main);
