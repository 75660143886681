import React, { useState } from 'react';
import  { format, addDays } from 'date-fns';
import { DateRange } from 'react-date-range';
import { MDBInput } from "mdbreact";
import OutsideClickHandler from 'react-outside-click-handler';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRangeSelector = props => {

    let useProps = {...props};
    delete useProps.ranges;
    delete useProps.updateRange;
    let classes = { className: props.className, style: props.style };
    const [state, setState] = useState({
        visible: false,
        range: [{
            startDate: new Date(props.ranges ? props.ranges.start : 0),
            endDate  : new Date(props.ranges ? props.ranges.end   : Date.now()),
            key: 'selection'
        }]
    });

    console.log(state, {
        start: state.range[0].startDate || 0,
        end: state.range[0].endDate || Date.now()
    });

    const handleSelect = item => {
        console.warn('handleSelect');

        let sel = item.selection;
        sel.endDate = new Date(addDays(sel.endDate, 1) - 1);

        let start = sel.startDate ? sel.startDate.getTime() : 0;
        let end = sel.endDate ? sel.endDate.getTime() : Date.now();

        console.warn({ start, end });
        if (start === end) return;
        setState({
            ...state,
            range: [{
                ...sel
            }]
        });

        props.updateRange({ start, end });
    }

    const handleFocus = event => {
        console.log(event);
        setState({
            ...state,
            visible: true
        });
    }

    const handleOutsideClick = event => {
        console.log(event);
        let classes = event.path[0].className;
        if (classes && classes.includes('date-trigger')) return;
        setState({
            ...state,
            visible: false
        });
    }

    return (<div {...classes}>
        <MDBInput className="date-trigger" label="Date Range" onFocus={handleFocus} value={`${format(state.range[0].startDate, "MMMM dd, yyyy")}-${format(state.range[0].endDate, "MMMM dd, yyyy")}`} />
        <div className={state.visible ? 'position-absolute' : 'd-none'} style={{ zIndex: 999 }}>
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                <DateRange
                    onChange={handleSelect}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    maxDate={new Date()}
                    ranges={state.range}
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                    {...useProps}
                />
            </OutsideClickHandler>
        </div>
    </div>);
};

export default DateRangeSelector;
