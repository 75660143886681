import React, { useReducer } from 'react';
import statsContext from "./statsContext";
import StatsReducer from "./statsReducer";
import { SET_STATS, SET_SEL } from "../types";
import axios from 'axios';

const StatsState = props => {

    const initialState = {
        stats: null,
        sel: null
    };

    const [state, dispatch] = useReducer(StatsReducer, initialState);

    // get the stats of a guild :
    const getStats = async options => {
        console.log('getStats');
        let { login_token, type, start, end, term, sel } = options;

        // let sel = term ? term :
        //     start && end ? `${start}_${end}` :
        //     start ? start :
        //     end ? end : null;
        // console.log(sel);
        if (!sel) return null;
        if (state.stats && state.stats[sel] && state.stats[sel][type]) return dispatch({
            type: SET_SEL,
            payload: { sel }
        });

        console.log('fetching stats:', type, sel);
        console.log(`//${process.env.REACT_APP_APIDOMAIN}/stats/${type}`);
        const res = await axios.post(`//${process.env.REACT_APP_APIDOMAIN}/stats/${type}`, new URLSearchParams({
            login_token: login_token || '',
            start: start ? start / 1000 : '',
            end: end ? end / 1000 : '',
            term: term || '',
            sel
        }), {
            "Content-Type": "application/x-www-form-urlencoded"
        }).catch(console.error);

        if (!res || !res.data) return;

        dispatch({
            type: SET_STATS,
            payload: { data: res.data, sel }
        });
    };

    return (
        <statsContext.Provider
            value={{
                stats: state.stats,
                curRange: state.sel,
                getStats
            }}
        >
        {props.children}
        </statsContext.Provider>
    )
};

export default StatsState;
