import React from 'react';
import { MDBCol, MDBCard } from 'mdbreact';
import Image from "./Image.component";
import { prettyTime } from "../global.js";
import "./stats.styles.css";

const StatsItem = props => {
    const { id, pos, name, image, count, total } = props;

    return (
        <MDBCol xl="3" lg="4" md="4" sm="6" className="col-xxl-2 col-6 my-2 px-2">
            <MDBCard className="user-card face front" cascade>
                {/* <MDBBadge className={`${color || ''} rank`} color="primary" pill>{props.pos}</MDBBadge> */}
                <div className="view overlay" data-test="view">{/* custom mdb card image (i hacked this together) */}
                    <Image
                        data-test="card-image"
                        className="img-fluid rounded-top aspect-ratio-1-1 w-100"
                        src={image}
                        alt={name + ''}
                    />
                    <div data-test="mask" className="mask rgba-white-slight" />
                </div>
                <div className="rounded-bottom unib-color lighten-3 pt-3" style={{ position: 'relative' }}>
                    <ul className='list-unstyled font-small px-3'>
                        <li className='username white-text'>
                            {pos}. <span className="artist-name">{name}</span>
                        </li>
                        <li>
                            <span className="m-0">{prettyTime(total)}</span>
                        </li>
                        <li className="right">
                            {/* <MDBTooltip
                                email
                                placement="right"
                            >
                                <MDBIcon icon="angle-double-right" className="clickable ml-2 mr-0" onClick={handleFlipping} />
                                <div>More info</div>
                            </MDBTooltip> */}
                        </li>
                    </ul>
                </div>
            </MDBCard>
        </MDBCol>
    );
};

/**
* we specify that we are expecting the "row" prop to be an object
* so that it bitches about it in the console if it's not a object
*/
// LeaderboardItem.propTypes = {
// row: PropTypes.object.isRequired,
// };

export default StatsItem;
