import React, { useContext } from 'react';
import { Route, Redirect } from "react-router-dom";
import authContext from '../../context/auth/authContext';
import { MDBIcon } from 'mdbreact';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const AuthContext = useContext(authContext);
    const { loaded, loggedIn } = AuthContext;

    if (!loaded) return <MDBIcon icon="spinner" spin size="3x" fixed />
    return (<Route
        {...rest}
        render={props =>
            loggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect to="/" />
            )
        }
    />)
}

export default PrivateRoute;
