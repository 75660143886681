import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { MDBIcon } from 'mdbreact';
import authContext from "../../context/auth/authContext";

const Loading = props => {

    const AuthContext = useContext(authContext);
    const { validateLogin, loggedIn } = AuthContext;

    const uid = new URLSearchParams(props.location.search).get("uid");
    const login_token = new URLSearchParams(props.location.search).get("login_token");
    console.log(props.location);
    localStorage.setItem("uid", uid);
    localStorage.setItem("login_token", login_token);

    let response = <Redirect to="/" />
    useEffect(() => {
        console.log({ login_token, loggedIn })
        if (login_token && !loggedIn) {
            console.log(1)
            validateLogin(login_token);
        } else if (!login_token) {
            console.log(2) // causing infinite redirect
        }
        // eslint-disable-next-line
    }, []);

    if (!login_token)
        response = <MDBIcon icon="spinner" spin size="3x" fixed />
    else return <Redirect to="/profile" />

    console.log({ response })
    return (
        null
        // response
    );
};

export default Loading;
