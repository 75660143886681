import React, { useState, useContext, useEffect } from 'react';
import { Link, Redirect, useParams, withRouter } from 'react-router-dom';
import authContext from '../../context/auth/authContext';
import statsContext from '../../context/stats/statsContext';
import StatsItem from '../stats/statsItem.component';
import DateRangeSelector from '../DateRangeSelector.component';
import { MDBRow, MDBIcon } from 'mdbreact';

const Profile = props => {

    const { user } = useParams();

    const AuthContext = useContext(authContext);
    const { loaded, loggedIn, login_token } = AuthContext;

    const StatsContext = useContext(statsContext);
    const { getStats, stats, curRange } = StatsContext;

    const [state, setState] = useState({
        range: {
            start: Date.now() - 7 * 24 * 60 * 60 * 1000,
            end: Date.now()
        }
    });

    console.log({ login_token, stats });
    let types = new URLSearchParams(props.location.search).get('type');
    types = types ? [types] : ['tracks', 'artists'];
    console.log(types);

    let sel = curRange;
    let test = `${state.range.start}_${state.range.end}`;

    const updateRange = item => {
        console.warn('updateRange');
        setState({ range: item });
        console.warn('range:', item);
    }

    useEffect(() => {
        console.log('profile: useEffect');

        const fetchStats = async () => {
            if (!user && !loggedIn) return;

            if (login_token) {
                console.log('fetchStats', types);
                let curSel = `${state.range.start}_${state.range.end}`;
                for (let each of types) {
                    await getStats({
                        login_token,
                        type: each,
                        ...state.range,
                        sel: curSel
                    });
                }
            }
        }

        fetchStats();
    }, [loaded, props.location.search, state.range.start, state.range.end]);

    if (loaded && !loggedIn && !user) return <Redirect to="/" />;
    if (!stats) return <MDBIcon icon="spinner" spin size="3x" fixed />

    let i = 0;
    let j = 0;
    let tracks  = types.indexOf('tracks')  !== -1 && stats[sel]['tracks']  ? Object.values(stats[sel]['tracks'])  : [];
    let artists = types.indexOf('artists') !== -1 && stats[sel]['artists'] ? Object.values(stats[sel]['artists']) : [];
    if (types.length > 1) {
        if (tracks.length)  tracks  = tracks.slice(0, 6);
        if (artists.length) artists = artists.slice(0, 6);
    }

    let dateSelector = <DateRangeSelector
        className="position-relative"
        style={{
            marginLeft: '2rem',
            minWidth: '16rem'
        }}
        updateRange={updateRange}
        ranges={state.range}
    />;
    return (<>
        {tracks.length ? <>
            <div className="d-flex">
                <Link to={{ pathname: props.location.pathname, search: '?type=tracks' }} className="unlink flex-1"><h2>Tracks</h2></Link>
                {types.length > 1 || types.indexOf('tracks') !== -1 ? dateSelector : null}
            </div>
            <MDBRow className={props.location.search ? 'mb-3' : 'summary mb-3'} center>
                {tracks.map(item => {
                    let { id, count, total } = item;
                    if (!count && !total) return;
                    return <StatsItem key={id} pos={++i} {...item} />
                })}
            </MDBRow>
        </> : (types.indexOf('tracks') !== -1 ? dateSelector : null)}

        {artists.length ? <>
            <div className="d-flex">
                <Link to={{ pathname: props.location.pathname, search: '?type=artists' }} className="unlink flex-1"><h2>Artists</h2></Link>
                {types.length === 1 && types.indexOf('artists') !== -1 ? dateSelector : ''}
            </div>
            <MDBRow className={props.location.search ? '' : 'summary'} center>
                {artists.map(item => {
                    let { id, count, total } = item;
                    if (!count && !total) return;
                    return <StatsItem key={id} pos={++i} {...item} />
                })}
            </MDBRow>
        </> : <>{(types.length === 1 && types.indexOf('artists') !== -1 ? dateSelector : '')} You have no songs from this time period :(</>}
    </>);
};

export default withRouter(Profile);
