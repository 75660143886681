
console.log('ayo')
export function prettyTime(ms) {
    let prefix = '';
    if (typeof ms === 'string') {
        ms = ms.split('');
        prefix = ms.splice(0, 1);
        ms = ms.join('');
    }

    let seconds = ~~(parseInt(ms) / 1000);
    if (typeof seconds !== 'number') return '';

    let minutes = ~~(seconds / 60);
    if (minutes < 1) return '<1 min';

    let hours = ~~(minutes / 60);
    if (hours < 1) return prefix + minutes + 'min';

    minutes %= 60;
    if (hours < 100) return prefix + hours + 'h' + (minutes ? minutes + 'm' : '');
    return prefix + hours + 'hr';
}
