import React from 'react';
// import Navbar from "./components/layout/navbar.component";
import StatsState from "./context/stats/statsState";
import AuthState from "./context/auth/authState";
import Main from "./components/Main";

const App = () => {
    return (
        <AuthState>
            <StatsState>
                <>
                    {/* <header>
                        <Navbar />
                    </header> */}
                    <Main />
                </>
            </StatsState>
        </AuthState>
    );
}

export default App;
