import {  SET_LOGIN_TOKEN } from "../types";

const authReducer = (state, action) => {

    switch(action.type) {

        case SET_LOGIN_TOKEN:
            console.log(SET_LOGIN_TOKEN);
            let { login_token } = action.payload;
            login_token ? localStorage.setItem("login_token", login_token) : localStorage.removeItem("login_token");
            return {
                ...state,
                loaded: true,
                loggedIn: !!login_token,
                login_token
            }

        default: return state;
    }
}

export default authReducer;