import React, { useReducer, useEffect } from 'react';
import authContext from "./authContext";
import authReducer from "./authReducer";
import { SET_LOGIN_TOKEN } from "../types";
import axios from 'axios';

const AuthState = props => {

    const initialState = {
        loaded: false,
        loggedIn: false,
        login_token: null
    };

    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        console.log('AuthState: useEffect');

        if (state.loggedIn) return;
        const login = async () => {
            let token = localStorage.getItem('login_token');
            if (!state.loaded)
                await validateLogin(token);
        }

        login();
    }, []);

    // check if login token is valid:
    const validateLogin = async login_token => {
        console.log('validateLogin');

        if (!login_token) return dispatch({
            type: SET_LOGIN_TOKEN,
            payload: {}
        });

        let res = await axios.post(`//${process.env.REACT_APP_APIDOMAIN}/auth/verifyIntegrity`, new URLSearchParams({
            login_token
        }), {
            "Content-Type": "application/x-www-form-urlencoded"
        }).catch(console.error);

        if (!res || !res.data) return;
        dispatch({
            type: SET_LOGIN_TOKEN,
            payload: res.data
        });

        return true;
    };

    return (
        <authContext.Provider 
            value={{
                loaded: state.loaded,
                loggedIn: state.loggedIn,
                login_token: state.login_token,
                validateLogin
            }}
        >
            {props.children}
        </authContext.Provider>
    )
};

export default AuthState;