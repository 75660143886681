import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import authContext from "../../context/auth/authContext";
import { MDBIcon } from 'mdbreact';

const Home = () => {

    const AuthContext = useContext(authContext);
    const { validateLogin, loaded, login_token, loggedIn } = AuthContext;

    useEffect(() => {
        console.log('ayo', loggedIn);

        const login = async () => {
            let token = localStorage.getItem('login_token');
            if (!loggedIn && token)
                validateLogin(token);
        }

        login();
        // eslint-disable-next-line
    }, [loggedIn]);

    // const StatsContext = useContext(statsContext);
    // const { getStats, rows, total, roles } = StatsContext;

    if (!loaded) return <MDBIcon icon="spinner" spin size="3x" fixed />

    console.log(login_token, loggedIn);
    return (<>
        <h1>SPOTIFY STATS TRACKING THINGY</h1>
        {login_token && loggedIn ?
            <Redirect to="/profile" /> :
            <a href={`//${process.env.REACT_APP_APIDOMAIN}/auth/login`} className="btn btn-outline-info btn-rounded waves-effect">Login</a>}
    </>);
};

export default Home;
