import React, { useState, useContext, useEffect } from 'react';
import { Link, Redirect, useParams, withRouter } from 'react-router-dom';
import authContext from '../../context/auth/authContext';
import statsContext from '../../context/stats/statsContext';
import StatsItem2 from '../stats/statsItem2.component';
import DateRangeSelector from '../DateRangeSelector.component';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import StatsItem from '../stats/statsItem.component';

const Dashboard = props => {

    const { user } = useParams();

    const AuthContext = useContext(authContext);
    const { loaded, loggedIn, login_token } = AuthContext;

    const StatsContext = useContext(statsContext);
    const { getStats, stats, curRange } = StatsContext;

    const [state, setState] = useState({
        range: {
            start: Date.now() - 7 * 24 * 60 * 60 * 1000,
            end: Date.now()
        }
    });

    console.log({ login_token, stats });
    let types = new URLSearchParams(props.location.search).get('type');
    types = types ? [types] : ['tracks', 'artists'];
    console.log(types);

    let sel = curRange;
    let test = `${state.range.start}_${state.range.end}`;

    const updateRange = item => {
        console.warn('updateRange');
        setState({ range: item });
        console.warn('range:', item);
    }

    useEffect(() => {
        console.log('profile: useEffect');

        const fetchStats = async () => {
            if (!user && !loggedIn) return;
    
            if (login_token) {
                console.log('fetchStats', types);
                let curSel = `${state.range.start}_${state.range.end}`;
                for (let each of types) {
                    await getStats({
                        login_token,
                        type: each,
                        ...state.range,
                        sel: curSel
                    });
                }
            }
        }

        fetchStats();
    }, [loaded, props.location.search, state.range.start, state.range.end]);

    if (loaded && !loggedIn && !user) return <Redirect to="/" />;
    if (!stats) return <MDBIcon icon="spinner" spin size="3x" fixed />

    let tracks  = types.indexOf('tracks')  !== -1 && stats[sel]['tracks']  ? Object.values(stats[sel]['tracks']) .slice(0, 5).map(item => item || {}) : [];
    let artists = types.indexOf('artists') !== -1 && stats[sel]['artists'] ? Object.values(stats[sel]['artists']).slice(0, 5).map(item => item || {}) : [];

    let dateSelector = <DateRangeSelector
        className="position-relative"
        style={{
            marginLeft: '2rem',
            minWidth: '16rem'
        }}
        updateRange={updateRange}
        ranges={state.range}
    />;

    console.log(tracks, artists);

    let i = 0;
    let j = 0;
    return (<>
        <div className="row">
            <div className="col-8">
                <div className="row mb-5">
                    <MDBCol className="blue square w-50 p-0"><StatsItem2 key={tracks[0].id} pos={++i} {...tracks[0]} /></MDBCol>
                    <MDBCol className="red square w-50 p-0">
                        <MDBRow>
                            <MDBCol className="purple square w-50 p-0"><StatsItem2 key={tracks[1].id} pos={++i} {...tracks[1]} /></MDBCol>
                            <MDBCol className="yellow square w-50 p-0"><StatsItem2 key={tracks[2].id} pos={++i} {...tracks[2]} /></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className="red square w-50 p-0"><StatsItem2 key={tracks[3].id} pos={++i} {...tracks[3]} /></MDBCol>
                            <MDBCol className="green square w-50 p-0"><StatsItem2 key={tracks[4].id} pos={++i} {...tracks[4]} /></MDBCol>
                        </MDBRow>
                    </MDBCol>
                </div>
                <div className="row">
                    <MDBCol className="blue square w-50 p-0"><StatsItem2 key={artists[0].id} pos={++i} {...artists[0]} /></MDBCol>
                    <MDBCol className="red square w-50 p-0">
                        <MDBRow>
                            <MDBCol className="purple square w-50 p-0"><StatsItem2 key={artists[1].id} pos={++i} {...artists[1]} /></MDBCol>
                            <MDBCol className="yellow square w-50 p-0"><StatsItem2 key={artists[2].id} pos={++i} {...artists[2]} /></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className="red square w-50 p-0"><StatsItem2 key={artists[3].id} pos={++i} {...artists[3]} /></MDBCol>
                            <MDBCol className="green square w-50 p-0"><StatsItem2 key={artists[4].id} pos={++i} {...artists[4]} /></MDBCol>
                        </MDBRow>
                    </MDBCol>
                </div>
            </div>
            <div className="col-4">
                how the fuck did you find this page
            </div>
        </div>
    </>);
};

export default Dashboard;
