export const SET_STATS = "SET_STATS";
export const SET_SEL = "SET_SEL";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_USER = "GET_USER";
export const SET_LOGIN_TOKEN = "SET_LOGIN_TOKEN";
export const GET_STATS = "GET_STATS";
export const UPDATE_SETTING = "GET_SETTINGS";
export const SET_PRESENT = "SET_PRESENT";
export const SET_SHOW = "SET_SHOW";
export const RESIZE_SIDEBAR = "RESIZE_SIDEBAR";
export const TOGGLE_SIDENAV = "TOGGLE_SIDENAV";
export const GET_COMMANDS = "GET_COMMANDS";
export const TOGGLE_COMMAND = "TOGGLE_COMMAND";
export const TOGGLE_ACTIVE = "TOGGLE_ACTIVE";