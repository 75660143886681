import { SET_STATS, SET_SEL } from "../types";

const StatsReducer = (state, action) => {

    let { data, sel } = action.payload;
    switch(action.type) {

        case SET_STATS:
            if (!data || !sel) return state;

            if (!data[sel])
                data[sel] = {};

            let { tracks, artists } = data[sel];
            let newState = state.stats || {};
            if (!newState[sel])
                newState[sel] = {};

            if (tracks)  newState[sel].tracks = tracks;
            if (artists) newState[sel].artists = artists;

            return {
                ...state,
                stats: {
                    ...state.stats,
                    ...newState
                }, sel
            };

        case SET_SEL:
            return sel ? { ...state, sel } : state;

        default: return state;
    }
}

export default StatsReducer;